import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../structure/Card";
import * as UserActions from "../../reducers/userReducer";

import LoginForm from "./LoginForm";
import { UserAPI } from "../../api";
import * as Alert from "../structure/Alert";

interface ILoginScreenProps {
  userActions: any;
  userState: any;
}

class Login extends React.Component<ILoginScreenProps, any> {

  constructor(props: ILoginScreenProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public async handleSubmit(values: any) {
    try {
      const result = await UserAPI.loginUser(values.email, values.password);
      window.localStorage.jwt = result.body.data.jwt;
      this.props.userActions.loginUser({
        loggedIn: true,
        user: result.body.data
      });
    } catch (err) {
      Alert.error("Could not log you in. Check with Software Engineering to make sure you have access");
    }
  }

  public render() {
    if (this.props.userState.loggedIn) {
      return (
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <Card title="Welcome">
              <strong>Thank you for logging in {`${this.props.userState.user.firstName}`}. Please select a report in the menu to the left.</strong>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <Card title="Login">
            <div className="row">
              <div className="col-md-12 pull-right">
                <LoginForm onSubmit={this.handleSubmit} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as React.ComponentType<any>);