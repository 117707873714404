import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  withRouter } from "react-router";
import { Modal } from "react-bootstrap";

import * as AppActions from "../../reducers/appReducer";
import Card from "../structure/Card";
import { error } from "../structure/Alert";
import Table from "../structure/Table";
import { BillingAPI } from "../../api";

interface IFreeUsersScreenState {
  loading: boolean;
  freeUsers: any;
  selectedUserEmail: string;
  newUserEmail: string;
  showDeleteModal: boolean;
}

interface IFreeUsersScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
}

class FreeUsersScreen extends React.Component<IFreeUsersScreenProps, IFreeUsersScreenState> {

  constructor(props: IFreeUsersScreenProps){
    super(props);
    this.state = {
      loading: false,
      freeUsers: [],
      selectedUserEmail: "",
      newUserEmail: "",
      showDeleteModal: false,
    };

    this.fetch = this.fetch.bind(this);
    this.renderActionsCell = this.renderActionsCell.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.confirmDeleteFreeUser = this.confirmDeleteFreeUser.bind(this);
    this.addFreeUser = this.addFreeUser.bind(this);
    this.deleteFreeUser = this.deleteFreeUser.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render(){
    return (
      <div className="row">
        <div className="col-md-4 col-md-offset-1">
          <Card title="Add New Free User" loading={this.state.loading}>
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" id="newUserEmail" className="form-control" value={this.state.newUserEmail} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-primary" onClick={this.addFreeUser}>Add Free User</button>
            </div>
          </Card>
        </div>
        <div className="col-md-6">
          <Card title="Users" loading={this.state.loading}>
          <Table
          data={this.state.freeUsers}
          columns={[
            {
              Header: "Email",
              accessor: "email",
              Cell: this.renderCell
            },
            {
              Header: "",
              Cell: this.renderActionsCell,
              accessor: "email",
              width: 50,
              sortable: false,
              filterable: false,
            },
          ]} />
          </Card>
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Warning:</strong> You are about to remove <strong>{this.state.selectedUserEmail}</strong> from the Free Users database. This cannot be undone.
            Further, this will not impact any existing devices; if they still have a device, they will not be charged. You will need to deactivate the device.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.deleteFreeUser}>Yes, Delete This User</button>
            <button className="btn btn-block" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private renderCell(cellProps: any){
    return (
      <span>{cellProps.value}</span>
    );
  }

  private renderActionsCell(cellProps: any){
    return (
      <span className="glyphicon glyphicon-remove text-danger" data-email={cellProps.value} onClick={this.confirmDeleteFreeUser} />
    );
  }

  private confirmDeleteFreeUser(e: any){
    const email = e.target.getAttribute("data-email");
    this.setState({selectedUserEmail: email, showDeleteModal: true});
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const result = await BillingAPI.getFreeUsers(this.props.appState.app);
        this.setState({ loading: false, freeUsers: result.body.data});
      }catch(err){
        error("Could not load free users for this platform");
        this.setState({loading: false});
      }
    });
  }

  private toggleDeleteModal(){
    this.setState({showDeleteModal: !this.state.showDeleteModal});
  }

  private async deleteFreeUser(){
    this.setState({loading: true}, async() => {
      try{
        await BillingAPI.deleteFreeUser(this.props.appState.app, this.state.selectedUserEmail);
        const users = [];
        for(const u of this.state.freeUsers){
          if(u.email !== this.state.selectedUserEmail){
            users.push(u);
          }
        }
        this.setState({freeUsers: users, loading: false, showDeleteModal: false, selectedUserEmail: ""});
      }catch(err){
        error("Could not delete that free user. Please contact engineering.");
        this.setState({loading: false, showDeleteModal: false, selectedUserEmail: ""});
      }
    });
  }

  private async addFreeUser(){
    this.setState({loading: true}, async () => {
      try{
        await BillingAPI.saveFreeUser(this.props.appState.app, this.state.newUserEmail);
        this.setState({newUserEmail: ""}, () => this.fetch());
      }catch(err){
        error("Could not add that user. Please contact engineering.");
      }
    });
  }

  private updateField(e: any){
    const s = this.state;
    s[e.target.id] = e.target.value;
    this.setState(s);
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(FreeUsersScreen));