import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

// css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "react-datetime/css/react-datetime.css";
import "../node_modules/react-table/react-table.css";

import "./css/app.css";

ReactDOM.render(
  <App />,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
