import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  withRouter } from "react-router";
import { Link } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import Card from "../structure/Card";
import Table from "../structure/Table";

interface IUsersListScreenState {
  loading: boolean;
}

interface IUsersListScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
}

class UsersListScreen extends React.Component<IUsersListScreenProps, IUsersListScreenState> {

  constructor(props: IUsersListScreenProps){
    super(props);
    this.state = {
      loading: false
    };
  }

  public render(){
    let loading = this.state.loading;
    if(!this.props.appUsersDevicesState[this.props.appState.app]){
      loading = true;
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <Card title="Users" loading={loading}>
          <Table
          data={this.props.appUsersDevicesState[this.props.appState.app].users || []}
          columns={[
            {
              Header: "First",
              accessor: "firstName",
              Cell: this.renderCell
            },
            {
              Header: "Last",
              accessor: "lastName",
              Cell: this.renderCell
            },
            {
              Header: "Email",
              accessor: "email",
              Cell: this.renderCell
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: this.renderCell
            },
            {
              Header: "Sign Up",
              accessor: "created",
              Cell: this.renderCell
            },
            {
              Header: "Devices Count",
              accessor: "devicesCount",
              Cell: this.renderCell
            },
          ]} />
          </Card>
        </div>
      </div>
    );
  }

  private renderCell(cellProps: any){
    return (
      <Link to={`users/${cellProps.original.id}`}>{cellProps.value}</Link>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(UsersListScreen));