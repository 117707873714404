import axios from "axios";

/**
 * The main method for calling the API. 
 * @param app the app to look at, such as bd, wagz2, petzi
 * @param method one of get, put, patch, post, delete
 * @param endpoint the endpoint of the server to hit, such as users/login
 * @param data 
 */
export function makeCall(app: string, method: string, endpoint: string, data: any = {}, download: boolean = false): Promise<any> {
  // so the API calls will differ depending on whom we are calling (bd, wagz2, petzi)
  // so we need to do some environment lookups
  let api = "http://localhost:3000";

  if(app === "bd"){
    api = process.env.REACT_APP_API_BD || "http://localhost:3000";
  } else if(app === "wagz2" || app === "cs" || app === "reporting" || app === "support"){
    api = process.env.REACT_APP_API_WAGZ || "http://localhost:3000";
  } else if(app === "petzi"){
    api = process.env.REACT_APP_API_PETZI || "http://localhost:3000";
  }

  // normalize
  if(api.charAt(api.length -1) === "/"){
    api = api.substr(0, api.length - 1);
  }
  let resource = endpoint;
  if(resource.charAt(resource.length -1) === "/"){
    resource = resource.substr(0, resource.length - 1);
  }
  if(resource.charAt(0) === "/"){
    resource = resource.substr(1, resource.length);
  }
  const url = `${api}/${resource}`;

  if(!data.count){
    data.count = 10000;
  }

  const meth = method.toLowerCase();
  const config: any = {
    method: meth,
    url,
    timeout: 15000,
    headers: {
      jwt: ""
    },
    params: {},
    data: {},
  };

  if(!data.jwt && window.localStorage.jwt){
    config.headers.jwt = window.localStorage.jwt;
    delete(data.jwt);
  } else if(data.jwt){
    config.headers.jwt = data.jwt;
    delete(data.jwt);
  }
  
  if (meth === "get") {
    config.params = data;
  } else {
    config.data = data;
  }

  if(download){
    config.responseType = "blob";
  }

  return axios(config)
  .then((res) => {
    return Promise.resolve({
      code: 200,
      body: res.data
    });
  })
  .catch((err) => {
    const ret = {
      code: 500,
      body: err
    };

    if(err.response && err.response.status) {
      ret.code = err.response.status;
      ret.body = err.response.data;
    }

    // we need to check for expired token here
    if(ret.code === 419 || ret.body.error === "Expired"){
      // should probably trigger a logout and redirect them?
    }
    return Promise.reject(ret);
  });
}