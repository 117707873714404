import * as React from "react";

interface IFormErrorProps {
  errors?: any;
  touched?: any;
  formikKey?: string;
  children?: any;
}

class FormError extends React.Component<IFormErrorProps, any> {

  public render() {
    if(this.props.errors !== undefined && this.props.touched !== undefined && this.props.formikKey !== undefined){
      // came from Formik
      return this.props.errors[this.props.formikKey] && this.props.touched[this.props.formikKey] ? 
        (<div className="text-danger">
            {this.props.errors[this.props.formikKey]}
          </div>) : null;
    }
    return (
      <div className="text-danger">
        {this.props.children}
      </div>
    );
  }
}

export default FormError;