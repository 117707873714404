import { makeCall } from "./client";

export class PetsAPI {

  public getPets(app: string, start: string, end: string): Promise<any> {
    return makeCall(app, "get", `reports/${app}/pets`, {start, end});
  }
  public getWalks(app: string, start: string, end: string): Promise<any> {
    return makeCall(app, "get", `reports/${app}/pets/walks`, {start, end});
  }
}