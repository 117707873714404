import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import * as AppActions from "../../reducers/appReducer";
import Card from "../structure/Card";
import { error, success } from "../structure/Alert";
import { UserAPI } from "../../api/index";

interface IResetPasswordScreenState {
  loading: boolean;
  email: string;
  confirmEmail: string;
  platform: string;
}

interface IResetPasswordScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
}

class ResetPasswordScreen extends React.Component<IResetPasswordScreenProps, IResetPasswordScreenState> {

  constructor(props: IResetPasswordScreenProps){
    super(props);
    this.state = {
      loading: false,
      email: "",
      confirmEmail: "",
      platform: "wagz2"
    };

    this.updateField = this.updateField.bind(this);
    this.send = this.send.bind(this);
  }

  public componentDidMount(){
    this.setState({
      platform: this.props.appState.app
    });
  }

  public render(){
    let loading = this.state.loading;
    if(!this.props.appUsersDevicesState[this.props.appState.app]){
      loading = true;
    }
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <Card title="Resend Password Verification" loading={loading}>
            <div className="form-group">
              <p><strong>Enter the user's email and their platform to have a manual password reset sent. This is for use if for some reason you cannot
                find them in the list. If the user does not exist on the platform, they will not receive the email!</strong></p>
            </div>
            <div className="form-group">
              <label>Platform</label>
              <select className="form-control" id="platform" value={this.state.platform} onChange={this.updateField}>
                <option value="wagz2">Wagz</option>
                <option value="bd">Black + Decker</option>
                <option value="petzi" disabled={true}>Petzi (Coming soon!)</option>
              </select>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input id="email" type="email" className="form-control" value={this.state.email} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Confirm Email</label>
              <input id="confirmEmail" type="email" className="form-control" value={this.state.confirmEmail} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.send}>Send Email</button>
            </div>
          </Card>
        </div>
      </div>
    );
  }


  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async send(){
    const { email, confirmEmail } = this.state;
    if(email === "" || email !== confirmEmail){
      return error("Email cannot be blank and must match the confirm email field");
    }
    try{
      await UserAPI.sendPasswordResetEmail(this.state.platform, email, {email});
      return success("If that email exists, your resend will be processed!");
    }catch(err){
      error("Could not send that email. Confirm you entered a valid email. The user may not exist and you may need to escalate");
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen));