import { makeCall } from "./client";

export class BillingAPI {

  public getBillingDevicesForUser(app: string, userId: number): Promise<any> {
    return makeCall(app, "get", `support/${app}/users/${userId}/billing/devices`, {});
  }

  public getBillingHistoryForUser(app: string, userId: number): Promise<any> {
    return makeCall(app, "get", `support/${app}/users/${userId}/billing/history`, {});
  }

  public getPlans(app: string, options: object = {}): Promise<any> {
    return makeCall(app, "get", `support/${app}/plans`, options);
  }

  public changePlanForDevice(app: string, userId: number, deviceId: number, planId: number, options: object = {}): Promise<any> {
    return makeCall(app, "post", `support/${app}/users/${userId}/devices/${deviceId}/plans/${planId}`, options);
  }

  public saveFreeUser(app: string, email: string, options: object = {}): Promise<any> {
    return makeCall(app, "put", `billing/freeUsers/${app}/${email}`, options);
  }

  public deleteFreeUser(app: string, email: string, options: object = {}): Promise<any> {
    return makeCall(app, "delete", `billing/freeUsers/${app}/${email}`, options);
  }

  public getFreeUsers(app: string, options: object = {}): Promise<any> {
    return makeCall(app, "get", `billing/freeUsers/${app}/`, options);
  }


  public changePaymentMethod(app: string, userId: number, token: string, otherData: object = {}){
    const data = {
      ...otherData,
      token
    };
    return makeCall(app, "put", `users/${userId}/billing`, data);
  }


  public getPaymentMethod(app: string, userId: number, otherData: object = {}){
    return makeCall(app, "get", `users/${userId}/billing`, otherData);
  }

  public getStripeToken(app: string, otherData: object = {}){
    return makeCall(app, "get", `plans/token`, otherData);
  }

}