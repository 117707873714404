import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

////
//// For now, this does nothing. Essentially, it will be a top-bar submenu to toggle between the
//// profile, billing, etc screens
//// 

interface IUserScreenMenuProps {
  selectedScreen: "profile" | "billing";
  userId: number;
  firstName?: string;
  lastName?: string;
  service?: string;
}

class UserScreenMenu extends React.Component<IUserScreenMenuProps, {}> {

  public render(){
    let display = "";
    if(this.props.service){
      if(this.props.service === "wagz" || this.props.service === "wagz2"){
        display += "Wagz - ";
      } else if(this.props.service === "bd" || this.props.service === "bd2"){
        display += "Black and Decker - ";
      } else {
        display += "Petzi - ";
      }
    }
    if(this.props.firstName){
      display = `${display} ${this.props.firstName}`;
    }
    if(this.props.lastName){
      display = `${display} ${this.props.lastName}`;
    }
    return (
      <div className="row">
          <div id="studentNavbar" className="col-md-8 col-md-offset-2">
            <nav className="navbar navbar-default" style={{borderRadius: "5px"}}>
              <div className="navbar-header">
              {display !== "" ?
                (<Link 
                  to={`/support/users/${this.props.userId}/`}
                  className="navbar-brand">
                  {`${display}`}
                </Link>) : null }
              </div>
              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav">
                  <li><Link to={`/support/users/${this.props.userId}/profile`}>Profile</Link></li>
                  <li><Link to={`/support/users/${this.props.userId}/billing`}>Billing</Link></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {};
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(UserScreenMenu));