import { createAction, createReducer } from "redux-act";
import * as moment from "moment";

export const showMessage: any = createAction("show message");
export const hideMessage: any = createAction("hide message");
export const toggleHelp: any = createAction("app - toggle help");
export const toggleMenu: any = createAction("app - toggle menu");
export const changeDates: any = createAction("app - change dates");
export const changeApp: any = createAction("app - change app");


export default createReducer({
    [showMessage]: (state, payload) => {
      // type should be one of error, success, warning
      return {
        ...state, 
        message: {
          type: payload.type,
          message: payload.message
        }
      };
    },
    [hideMessage]: (state) => {
      return {
        ...state, 
        message: {
          type: "",
          message: ""
        }
      };
    },
    [toggleHelp]: (state) => {
      const show = !state.showHelp;
      return {
        ...state, 
        showHelp: show
      };
    },
    [toggleMenu]: (state) => {
      const show = !state.showMenu;
      return {
        ...state, 
        showMenu: show
      };
    },
    [changeDates]: (state, payload) => {
      return {
        ...state,
        startDate: payload.startDate,
        endDate: payload.endDate
      };
    },
    [changeApp]: (state, payload) => {
      return {
        ...state,
        app: payload.app
      };
    }
  }, {
    message: {
      type: "",
      message: "",
    },
    showHelp: false,
    showMenu: false,
    showContact: false,
    startDate: moment().add(-1, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    app: "wagz2"
});