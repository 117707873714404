import { createAction, createReducer } from "redux-act";

export const updateAppUsers: any = createAction("app users - update");
export const updateAppDevices: any = createAction("app devices - update");
export const updateAppPlans: any = createAction("app plans - update");

const defaultState: any = {
  wagz2: {
    users: [],
    devices: [],
    plans: []
  },
  petzi: {
    users: [],
    devices: [],
    plans: []
  },
  bd: {
    users: [],
    devices: [],
    plans: []
  },
  bd2: {
    users: [],
    devices: [],
    plans: []
  },
};

export default createReducer({
  /** should come in with app: and users: keys */
  [updateAppUsers]: (state, payload) => {
    const newState = { ...state };
    newState[payload.app].users = payload.users;
    return newState;
  },
  /** should come in with app: and devices: keys */
  [updateAppDevices]: (state, payload) => {
    const newState = { ...state };
    newState[payload.app].devices = payload.devices;
    return newState;
  },
  /** should come in with app: and plans: keys */
  [updateAppPlans]: (state, payload) => {
    const newState = { ...state };
    newState[payload.app].plans = payload.plans;
    return newState;
  },
}, defaultState);