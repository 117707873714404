
import { UserAPI, DevicesAPI, BillingAPI } from "../api";


interface IProcessedUsersAndDevices {
  users: any;
  devices: any;
  plans: any;
}

const userIdMapping: any = {};

export const appChanged = async (app: string): Promise<IProcessedUsersAndDevices> => {
  const ret: IProcessedUsersAndDevices = {
    users: [],
    devices: [],
    plans: []
  };
  try {
    const userResult = await UserAPI.getUsersForApp(app);
    const devicesResult = await DevicesAPI.getDevicesByBrandAndType(app, "all");
    const plansResult = await BillingAPI.getPlans(app, {});
    for (let i = 0; i < userResult.body.data.length; i++) {
      const user = userResult.body.data[i];
      userIdMapping[user.id] = user;
      userIdMapping[user.id].devices = [];
      userIdMapping[user.id].devicesCount = 0;
      userIdMapping[user.id].index = i;
      userResult.body.data[i].devices = [];
    }

    // now loop over the devices
    for (const device of devicesResult.body.data) {
      const uId = device.registeredBy;
      if (userIdMapping.hasOwnProperty(uId)) {
        if (device.brand === "") {
          device.brand = device.service === "wagz2" ? "wagz" : device.service;
        }
        userIdMapping[uId].devices.push(device);
        device.registeredByInfo = {
          firstName: userIdMapping[uId].firstName,
          lastName: userIdMapping[uId].lastName,
          email: userIdMapping[uId].email,
        };
        userResult.body.data[userIdMapping[uId].index].devices.push(device);
        userResult.body.data[userIdMapping[uId].index].devicesCount += 1;
      }
    }
    const plans = {
      collar: [],
      door: [],
      feeder: [],
      pendant: [],
      tile: [],
      treatCam: [],
      shield: []
    };
    for(const plan of plansResult.body.data){
      if(plan.platform === app){
        if(plans[plan.deviceType]){
          plans[plan.deviceType].push(plan);
        }
      }
    }

    ret.users = userResult.body.data;
    ret.devices = devicesResult.body.data;
    ret.plans = plans;
    setStripe(app);
  } catch (err) {
    Promise.reject(err);
  }
  return ret;
};

export const setStripe = async (app: string) => {
    let stripeKey = process.env.REACT_APP_STRIPE;
    if(stripeKey === "" || !stripeKey){
      try{
        const tokRes = await BillingAPI.getStripeToken(app);
        stripeKey = tokRes.body.data.token;
      }catch(err){
        // fall back
        stripeKey = "pk_live_UYcEbjqu897EHx1TV0wNYtWD";
      }
    }
    (window as any).Stripe.setPublishableKey(stripeKey);
};