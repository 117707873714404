import { makeCall } from "./client";

export class DevicesAPI {

  public getDevicesByBrandAndType(app: string, deviceType: string = "all"): Promise<any> {
    return makeCall(app, "get", `support/${app}/devices/${deviceType}`, {});
  }

  public getDevicesForUser(app: string, userId: number): Promise<any> {
    return makeCall(app, "get", `support/${app}/users/${userId}/devices`, {});
  }

  public updateDeviceForUser(app: string, userId: number, deviceId: number, data: any = {}): Promise<any> {
    return makeCall(app, "patch", `support/${app}/users/${userId}/devices/${deviceId}`, data);
  }

  public getDeviceLogs(app: string, productId: string, data: any = {}): Promise<any> {
    return makeCall(app, "get", `devices/logs/${app}/${productId}`, data);
  }
}