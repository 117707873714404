import { makeCall } from "./client";

export class UserAPI {

  /**
   * Attempts to login a user
   * @param username 
   * @param password 
   */
  public loginUser(email: string, password: string): Promise<any> {
    return makeCall("cs", "post", "support/login", {email, password});
  }

  /**
   * Gets the users for a specific app
   * @param app 
   */
  public getUsersForApp(app: string, count: number = 1000000): Promise<any> {
    return makeCall(app, "get", `support/${app}/users`, {count, limit: count});
  }

  /**
   * Gets a user's profile
   * @param app 
   * @param userId 
   */
  public getUserProfile(app: string, userId: number): Promise<any> {
    return makeCall(app, "get", `support/${app}/users/${userId}`, {});
  }

  /**
   * Update a user's profile
   * @param app 
   * @param userId 
   * @param data 
   */
  public updateUserProfile(app: string, userId: number, data: object): Promise<any> {
    return makeCall(app, "patch", `support/${app}/users/${userId}`, data);
  }

  public updateUserProfileSettings(app: string, userId: number, data: object): Promise<any> {
    return makeCall(app, "patch", `support/${app}/users/${userId}/settings`, data);
  }

  /**
   * Resend the verification email
   * @param app 
   * @param userId 
   * @param data 
   */
  public resendUserVerificationEmail(app: string, email: string, data: object): Promise<any> {
    data = {
      ...data,
      email
    };
    return makeCall(app, "post", `support/${app}/users/verify/resend`, data);
  }

  /**
   * Send the password reset email again
   * @param app 
   * @param email 
   * @param data 
   */
  public sendPasswordResetEmail(app: string, email: string, data: object): Promise<any> {
    data = {
      ...data,
      email
    };
    return makeCall(app, "post", `support/${app}/resetPassword`, data);
  }
  

}