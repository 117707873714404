import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { withRouter } from "react-router";

import * as AppActions from "../../reducers/appReducer";

interface ITableProps {
  data: any;
  defaultPageSize: number;
  columns: any;
}

class Table extends React.Component<ITableProps, {}> {

  public componentWillReceiveProps(props: any){
    this.setState({
      ...props
    });
  }

  public render(){
    return (
      <ReactTable
        data={this.props.data}
        defaultPageSize={this.props.defaultPageSize ? this.props.defaultPageSize : 20}
        className="-striped -highlight"
        filterable={true}
        columns={this.props.columns} />
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Table));