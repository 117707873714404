import { createAction, createReducer } from "redux-act";

export const loginUser: any = createAction("login");
export const logoutUser: any = createAction("logout");

export default createReducer({
    [loginUser]: (state, payload) => {
      return {
        ...state, 
        loggedIn: payload.loggedIn,
        user: payload.user,
      };
    },
    [logoutUser]: (state) => {
      return {
        ...state, 
        loggedIn: false,
        user: {}
      };
    },
  }, {
    loggedIn: false,
    user: {}
});