import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Card from "./structure/Card";


class Home extends React.Component<any, any> {
  public render() {
    return (
      <Card title="Welcome!">Hello!
      {this.props.userState.loggedIn ? 
      (
        <p>Please choose an option in the menu!</p>
      ) : 
      (
        <p><Link to="/login">Login</Link></p>
      )}
      </Card>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)  as React.ComponentType<any>);