import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as moment from "moment";

import * as AppActions from "../../reducers/appReducer";
import { DevicesAPI } from "../../api";
import { error } from "../structure/Alert";
import Card from "../structure/Card";
import Table from "../structure/Table";

interface IDeviceLogsScreenState {
  loading: boolean;
  productId: string;
  logs: IDeviceLog[];
}

interface IDeviceLog {
  id: number;
  level: string;
  dump: any;
  entered: string;
  app: string;
}

interface IDeviceLogsScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
  location: any;
}

class DeviceLogsScreen extends React.Component<IDeviceLogsScreenProps, IDeviceLogsScreenState> {

  constructor(props: IDeviceLogsScreenProps){
    super(props);
    this.state = {
      loading: false,
      productId: "",
      logs: []
    };
    this.updateField = this.updateField.bind(this);
    this.fetchLogs = this.fetchLogs.bind(this);
  }

  public componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    const productId = query.get("productId");
    this.setState({
      productId: productId ? productId : ""
    }, () => {
      this.fetchLogs();
    });
  }

  public render(){
    let loading = this.state.loading;
    if(!this.props.appUsersDevicesState[this.props.appState.app]){
      loading = true;
    }
    return (
      <div className="row">
        <div className="col-md-2">
          <Card title="Device Logs" loading={loading}>
            <div className="form-group">
              <label>Enter Product ID / Serial</label>
              <input type="text" className="form-control" id="productId" value={this.state.productId} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <button className="btn btn-block btn-success" onClick={this.fetchLogs}>Get Logs</button>
            </div>
          </Card>
        </div>
        <div className="col-md-10">
          <Card title="Devices" loading={this.state.loading}>
            <Table
              data={this.state.logs || []}
              columns={[
                {
                  Header: "Time",
                  accessor: "entered",
                  Cell: this.renderTimeCell
                  
                },
                {
                  Header: "Level",
                  accessor: "level"
                },
                {
                  Header: "Dump",
                  accessor: "dump"
                },
              ]} />
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private async fetchLogs(){
    if(this.state.productId !== ""){
      this.setState({loading: true}, async () => {
        try {
          const res = await DevicesAPI.getDeviceLogs(this.props.appState.app, this.state.productId, {});
          this.setState({
            logs: res.body.data,
            loading: false
          });
        } catch(err){
          this.setState({loading: false}, () => {
            return error("Could not fetch device logs");
          });
        }
      });
    }
  }

  private renderTimeCell(cellProps: any){
    const parsed = moment(cellProps.original.entered);
    return (
      <span>{cellProps.original.entered} ({parsed.fromNow()})</span>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(DeviceLogsScreen));