import * as React from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

import FormError from "../structure/FormError";

interface ILoginFormValues {
  email: string;
  password: string;
}

const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required")
});

class LoginForm extends React.Component<any, any> {

  constructor(props: any){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderEmail = this.renderEmail.bind(this);
    this.renderPassword = this.renderPassword.bind(this);
  }

  public handleSubmit(values: ILoginFormValues) {
    this.props.onSubmit(values);
  }

  public renderForm(props: FormikProps<ILoginFormValues>) {
    return (
      <Form>
        <Field
          name="email"
          render={this.renderEmail}
        />
        <FormError {...props} formikKey="email" />
        <Field
          name="password"
          render={this.renderPassword}
        />
        <FormError {...props} formikKey="password" />
        <button type="submit" className="btn btn-block btn-success">
          Login
        </button>
      </Form>);
  }

  public renderEmail({ field, form }: FieldProps<ILoginFormValues>) {
    return (
        <input type="text" {...field} placeholder="Email Address" className="form-control"/>
    );
  }

  public renderPassword({ field, form }: FieldProps<ILoginFormValues>) {
    return (
        <input type="password" {...field} placeholder="Password"  className="form-control"/>
    );
  }

  public render() {
    return (
      <div>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={this.handleSubmit}
          render={this.renderForm}
          validationSchema={validationSchema}
        />
      </div>
    );
  }
}

export default LoginForm;
