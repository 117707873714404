import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";

import logo from "../../img/wagz-logo.png";

interface IMenuProps {
  appState: any;
  appActions: any;
  userActions: any;
  history: any;
}

interface IMenuState {
  open: boolean;
}

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: any){
    super(props);
    const defaultState: IMenuState = {
      open: props.open ? props.open : true,
    };
    this.state = defaultState;

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onTransition = this.onTransition.bind(this);
    this.logout = this.logout.bind(this);
  }

  public componentWillReceiveProps(props: any){
    this.setState({
      ...props
    });
  }

  public toggleOpen(){
    this.setState({
      open: !this.state.open
    });
  }

  public onTransition(){
    this.props.appActions.toggleMenu();
  }

  public logout(){
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.appActions.toggleMenu();
    this.props.history.push("/login");
  }

  get header(){
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" /></div>
        {this.props.children}
      </div>
    );
  }

  get links(){
    return (
      <div style={styles.sidebar}>
        <div style={styles.header}><img src={logo} alt="logo" /></div>
          <div style={styles.content}>
          <div style={styles.divider} />

          <span style={styles.linkHeader}>Admin</span>
          <Link to="/support/users" style={styles.sidebarLink} onClick={this.onTransition}>Users</Link>
          <Link to="/support/devices" style={styles.sidebarLink} onClick={this.onTransition}>Devices</Link>
          <Link to="/support/devices/logs" style={styles.sidebarLink} onClick={this.onTransition}>Device Logs</Link>
          <Link to="/support/billing/freeUsers" style={styles.sidebarLink} onClick={this.onTransition}>Free Users</Link>
          <div style={styles.divider} />

          <span style={styles.linkHeader}>Other Tools</span>
          <Link to="/support/tools/resend/verification" style={styles.sidebarLink} onClick={this.onTransition}>Resend User Verification Email</Link>
          <Link to="/support/tools/resend/password" style={styles.sidebarLink} onClick={this.onTransition}>Send Password Reset Email</Link>
          <div style={styles.divider} />
          
          <span style={styles.linkHeader}>Configuration</span>
          <a href="#" onClick={this.logout} style={styles.sidebarLink}>Logout</a>
      </div>
      </div>
    );
  }

  public render(){
    return (
      <Sidebar 
        sidebar={this.links}
        open={this.props.appState.showMenu}
        onSetOpen={this.onTransition}>
        {this.props.children}
    </Sidebar>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Menu));


const styles: any = {
  sidebar: {
    width: 256,
    height: "100%",
  },
  sidebarLink: {
    display: "block",
    paddingLeft: "8px",
    paddingTop: "2px",
    color: "#757575",
    textDecoration: "none",
  },
  linkHeader: {
    display: "block",
    paddingTop: "12px",
    color: "#757575",
    textDecoration: "none",
    fontWeight: "bold"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#e0e0e0",
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "white",
  },
  root: {
    fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    fontWeight: 300,
  },
  header: {
    backgroundColor: "#101010",
    color: "white",
    padding: "16px",
    fontSize: "1.5em",
  },
};