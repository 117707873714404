import * as React from "react";
import { Panel } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { withRouter } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";

interface ICardState {
  open: boolean;
  showHelp: boolean;
  help: string;
  message: string;
  messageClass: string;
}

interface ICardProps {
  title: string;
  help: string;
  loading: boolean;
  appState: any;
  children?: any;
}

class Card extends React.Component<ICardProps, ICardState> {
  constructor(props: any){
    super(props);
    const defaultState: ICardState = {
      open: props.open ? props.open : true,
      showHelp: false,
      help: props.help ? props.help : "",
      message: props.message ? props.message : "",
      messageClass: props.messageClass ? props.messageClass : ""
    };
    this.state = defaultState;

    this.toggleOpen = this.toggleOpen.bind(this);
    this.toggleMessage = this.toggleMessage.bind(this);
  }

  public componentWillReceiveProps(props: any){
    this.setState({
      ...props
    });
  }

  public toggleOpen(){
    this.setState({
      open: !this.state.open
    });
  }

  public toggleMessage(){
    this.setState({
      message: "",
      messageClass: ""
    });
  }

  get title(){
    if(this.props.title === ""){
      return (<span />);
    }
    return this.props.title;
  }

  get help() {
    if(this.props.appState.showHelp && this.props.help && this.props.help !== ""){
      return (
        <div className="help-container">{this.props.help}</div>
      );
    } else {
      return (<span />);
    }
  }

  get message(){
    if(this.state.message && this.state.message !== ""){
      const styles: React.CSSProperties = {
        padding: "7px",
        textAlign: "center",
        width: "90%",
        marginLeft: "5%",
        marginBottom: "20px"
      };
      const className: string = this.state.messageClass && this.state.messageClass !== "" ? this.state.messageClass : "";
      return (
        <div className={className} style={styles} onClick={this.toggleMessage}>
          {this.state.message} 
          <span className="pull-right glyphicon glyphicon-remove" />
        </div>
      );
    } else {
      return (<span />);
    }
  }
  
  public render(){
    return (
        <Panel expanded={this.state.open} onToggle={this.toggleOpen}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{this.title}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              {this.help}
              {this.message}
              {this.props.loading && 
                <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />
              }
              {!this.props.loading &&  
                this.props.children
              }
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Card);