import * as React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter as Router, Route } from "react-router-dom";

import store from "./store";

import Menu from "./components/structure/Menu";
import Nav from "./components/structure/Nav";

// get the screens
import Home from "./components/Home";
import Login from "./components/Login/LoginScreen";

import UsersListScreen from "./components/UsersList/UsersListScreen";
import FreeUsersScreen from "./components/FreeUsers/FreeUsersScreen";
import DevicesListScreen from "./components/DevicesList/DevicesListScreen";
import DeviceLogsScreen from "./components/DeviceLogsScreen/DeviceLogsScreen";
import UserScreen from "./components/UserScreen/UserScreen";
import ResendPasswordScreen from "./components/ResendTools/ResendPassword";
import ResendVerificationScreen from "./components/ResendTools/ResendVerification";

class App extends React.Component {

  public render() {
    return (
      <Provider store={store}>
        <Router>
          <Menu>
            <div className="container-fluid">
              <Nav />
              <div>
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <Route exact={true} path="/" component={Home} />
                    <Route exact={true} path="/login" component={Login} />
                    <Route exact={true} path="/support/users" component={UsersListScreen} />
                    <Route exact={true} path="/support/billing/freeUsers" component={FreeUsersScreen} />
                    <Route exact={true} path="/support/devices" component={DevicesListScreen} />
                    <Route exact={true} path="/support/devices/logs" component={DeviceLogsScreen} />
                    <Route exact={true} path="/support/users/:userId" component={UserScreen} />
                    <Route exact={true} path="/support/users/:userId/:selectedScreen" component={UserScreen} />
                    <Route exact={true} path="/support/tools/resend/password" component={ResendPasswordScreen} />
                    <Route exact={true} path="/support/tools/resend/verification" component={ResendVerificationScreen} />
                  </div>
                </div>
              </div>

              <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={true} />
            </div>
          </Menu>
        </Router>
      </Provider>
    );
  }
}

export default App;
