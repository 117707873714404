import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import * as moment from "moment";

import { DevicesAPI, UserAPI, BillingAPI } from "../../api/index";

import Card from "../structure/Card";
import Table from "../structure/Table";
import { error, success } from "../structure/Alert";
import format from "../../utils/currency";

interface IUserProfileScreenState {
  loading: boolean;
  devices: any;
  profile: any;
  showSaveConfirm: boolean;
  showPlanConfirm: boolean;
  newPlan: string; // would rather be number, but <select> sends the id as a string
  newPlanDevice: string;
}

interface IUserProfileScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
  userId: number;
}

class UserProfileScreen extends React.Component<IUserProfileScreenProps, IUserProfileScreenState> {

  constructor(props: IUserProfileScreenProps) {
    super(props);
    this.state = {
      loading: true,
      devices: [],
      profile: {
        firstName: "",
        lastName: "",
        email: "",
        created: "",
        status: "active",
        password: "",
        confirmPassword: "",
        settings: {
          timezone: "America/New_York"
        }
      },

      showSaveConfirm: false,
      showPlanConfirm: false,
      newPlan: "0",
      newPlanDevice: "0",
    };

    this.fetchUserInformation = this.fetchUserInformation.bind(this);
    this.renderStatusCell = this.renderStatusCell.bind(this);
    this.renderPlansCell = this.renderPlansCell.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleSaveConfirm = this.toggleSaveConfirm.bind(this);
    this.resendAccountSetupVerification = this.resendAccountSetupVerification.bind(this);
    this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.togglePlanConfirm = this.togglePlanConfirm.bind(this);
    this.updatePlan = this.updatePlan.bind(this);  
    this.saveNewPlan = this.saveNewPlan.bind(this);
  }

  public componentDidMount() {
    this.fetchUserInformation();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div>
            <div className="row">
              <div className="col-md-4">
                <Card title="Profile" loading={this.state.loading}>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input 
                      id="firstName"
                      type="text"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.firstName} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input 
                      id="lastName"
                      type="text"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.lastName} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input 
                      id="email"
                      type="email"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.email} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input 
                      id="password"
                      type="text"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.password} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input 
                      id="confirmPassword"
                      type="text"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.confirmPassword} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select 
                      id="status"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.profile.status}>
                      <option value="active">Active</option>
                      <option value="pending" disabled={true}>Pending</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="created">Signed Up</label>
                    <input 
                      id="created"
                      type="text"
                      className="form-control"
                      disabled={true}
                      onChange={this.updateField}
                      value={moment(this.state.profile.created).format("MMM DD, YYYY")} />
                  </div>
                  {this.props.appState.app !== "wagz2" ? null : (
                    <div className="form-group">
                      <label htmlFor="created">Timezone</label>
                      <select 
                        id="timezone"
                        className="form-control"
                        onChange={this.updateSettings}
                        value={this.state.profile.settings.timezone}>
                        <option value="America/Puerto_Rico">Puerto Rico (Atlantic)</option>
                        <option value="America/New_York">New York (Eastern)</option>
                        <option value="America/Chicago">Chicago (Central)</option>
                        <option value="America/Denver">Denver (Mountain)</option>
                        <option value="America/Phoenix">Phoenix (MST)</option>
                        <option value="America/Los_Angeles">Los Angeles (Pacific)</option>
                        <option value="America/Anchorage">Anchorage (Alaska)</option>
                        <option value="Pacific/Honolulu">Honolulu (Hawaii)</option>
                      </select>
                    </div>
                  )}
                  <div className="form-group">
                    <button className="btn btn-success btn-block" onClick={this.toggleSaveConfirm}>Save</button>
                  </div>
                  <div className="form-group">
                    <label>Other Actions</label>
                    <button className="btn btn-primary btn-block" onClick={this.resendAccountSetupVerification}>Resend Verification Email</button>
                    <button className="btn btn-primary btn-block" onClick={this.sendPasswordResetEmail}>Send Password Reset Email</button>
                  </div>
                </Card>
              </div>
              <div className="col-md-8">
                <Card title="Devices" loading={this.state.loading}>
                  <Table
                    data={this.state.devices || []}
                    columns={[
                      {
                        Header: "Type",
                        accessor: "deviceType"
                      },
                      {
                        Header: "Nickname",
                        accessor: "nickname"
                      },
                      {
                        Header: "Serial",
                        accessor: "productId"
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        Cell: this.renderStatusCell
                      },
                      {
                        Header: "Plan",
                        Cell: this.renderPlansCell,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "",
                        Cell: this.renderActionsCell,
                        sortable: false,
                        filterable: false,
                        width: 20
                      }
                    ]} />
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Card title="Pets" loading={this.state.loading}>
                  Coming soon
                  Note that for BD, they have Devices, not Pets
                </Card>
              </div>
              <div className="col-md-4">
                <Card title="Addresses" loading={this.state.loading}>
                  Coming soon
                </Card>
              </div>
            </div>
          </div>
        </div>

        

        <Modal show={this.state.showSaveConfirm} onHide={this.toggleSaveConfirm}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to change this user's information? If you are changing their password, encourage them to immediately change it upon logging in. If you changed their status, warn them that without a verified email, they may miss critical information such as invoices or product updates.</p></strong>
              <p>Also note that simply changing their account to inactive will not cancel their devices. Please ensure you update those devices in the device list.</p>
              
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.updateProfile}>Update Profile</button>
            <button className="btn btn-danger" onClick={this.toggleSaveConfirm}>Nevermind</button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showPlanConfirm} onHide={this.togglePlanConfirm}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Change Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <strong><p>Are you absolutely sure you want to change this user's plan? If you choose to do so, you will cause the card to be charged if appropriate (for example, during an upgrade). If you have any questions at all, check with a supervisor first!</p></strong>
              
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={this.saveNewPlan}>Save Plan and Potentially Charge Customer</button>
            <button className="btn btn-danger" onClick={this.togglePlanConfirm}>Nevermind</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  // this updates a profile field
  private updateField(e: any) {
    const ns: any = this.state;
    ns.profile[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateSettings(e: any){
    const ns: any = this.state.profile;
    ns.settings[e.target.id] = e.target.value;
    this.setState({
      profile: ns
    });
  }

  private toggleSaveConfirm(){
    this.setState({
      showSaveConfirm: !this.state.showSaveConfirm
    });
  }

  private togglePlanConfirm(){
    this.setState({
      showPlanConfirm: !this.state.showPlanConfirm
    });
  }

  private async updateProfile(){
    const profile = this.state.profile;
    this.setState({
      showSaveConfirm: false
    }, async () => {
      try {
        await UserAPI.updateUserProfile(this.props.appState.app, this.props.userId, this.state.profile);
        // if they are Wagz, we need to also save settings
        if(this.props.appState.app === "wagz2"){
          await UserAPI.updateUserProfileSettings(this.props.appState.app, this.props.userId, this.state.profile.settings);
        }
        success("Successfully saved!");
        this.setState({ ...profile, password: "", confirmPassword: "" });
      } catch(err){
        if(err.code && err.code === 409){
          return error("That email address already exists on the platform. If this is a merge request, you must escalate to engineering.");
        }
        error("Could not update that user information. Make sure you changed information and that the password is valid.");
        this.setState({ ...profile, password: "", confirmPassword: "" });
      }  
    });
  }

  private async saveNewPlan(){
    const planId = parseInt(this.state.newPlan, 10);
    const deviceId = parseInt(this.state.newPlanDevice, 10);
    this.setState({
      loading: true,
    }, async ()=>{
      try {
        await BillingAPI.changePlanForDevice(this.props.appState.app, this.props.userId, deviceId, planId);
      }catch(err){
        error("We could not change that plan. Likely, the user does not have a valid card on file");
      }finally{
        this.setState({
          showPlanConfirm: false,
          newPlan: "",
          newPlanDevice: "",
          loading: false
        });
      }
  
    });
  }

  private async resendAccountSetupVerification(){
    const email = this.state.profile.email;
    try{
      await UserAPI.resendUserVerificationEmail(this.props.appState.app, email, {email});
      return success("If that is a valid email for a user in pending status, the verification email was sent!");
    }catch(err){
      return error("Could not send that verification email. Please make sure the user is in pending status first.");
    }
  }

  private async sendPasswordResetEmail(){
    const email = this.state.profile.email;
    try{
      await UserAPI.sendPasswordResetEmail(this.props.appState.app, email, {email});
      return success("If that is a valid email for an active user, the password reset email was sent!");
    }catch(err){
      return error("Could not send that password reset email");
    }
  }

  private async fetchUserInformation() {
    try {
      const profileResult = await UserAPI.getUserProfile(this.props.appState.app, this.props.userId);
      const devResult = await DevicesAPI.getDevicesForUser(this.props.appState.app, this.props.userId);
      // TODO: in order to support plan changes, we need to oop and get the plans for all the devices :(
      this.setState({ devices: devResult.body.data, 
        profile: {
          firstName: profileResult.body.data.firstName,
          lastName: profileResult.body.data.lastName,
          email: profileResult.body.data.email,
          created: profileResult.body.data.created,
          status: profileResult.body.data.status,
          password: "",
          confirmPassword: "",
          settings: {
            timezone: profileResult.body.data.settings && profileResult.body.data.settings.timezone ? profileResult.body.data.settings.timezone : "America/New_York"
          }
        },
        loading: false });
    } catch (err) {
      this.setState({ devices: [],
        loading: false});
    }
  }

  private async updateStatus(e: any){
    const deviceId = e.target.id;
    const value = e.target.value;
    try{
      await DevicesAPI.updateDeviceForUser(this.props.appState.app, this.props.userId, deviceId, { status: value });
      success("Device status updated!");
    } catch(err) {
      error("Could not update the device status");
    }
  }

  private renderStatusCell(cellProps: any) {
    return (
      <select 
        id={cellProps.original.id}
        className="form-control"
        value={cellProps.original.status}
        onChange={this.updateStatus}>
        <option value="active">Active</option>
        <option value="cancel">Cancel</option>
        <option value="deactivated">Deactivated</option>
      </select>
    );
  }

  private renderPlansCell(cellProps: any) {
    if(cellProps.original.status !== "active"){
      return null;
    }
    const options: any = [];
    if(this.props.appUsersDevicesState[this.props.appState.app] && this.props.appUsersDevicesState[this.props.appState.app].plans[cellProps.original.deviceType]){
      for(const p of this.props.appUsersDevicesState[this.props.appState.app].plans[cellProps.original.deviceType]){
        options.push(<option value={p.id} key={p.id}>{p.display} - {p.term} - {format(p.amount)}</option>);
      }
    }
    
    return (
      <select 
        id={`plan-${cellProps.original.id}`}
        className="form-control"
        value={cellProps.original.planInfo.currentPlanId ? cellProps.original.planInfo.currentPlanId : 0}
        onChange={this.updatePlan}>
        {options}
      </select>
    );
  }

  private async updatePlan(e: any){
    const deviceId = e.target.id.split("-")[1];
    const planId = e.target.value;
    this.setState({
      newPlan: planId,
      newPlanDevice: deviceId,
      showPlanConfirm: true
    });
  }
    

  private renderActionsCell(cellProps: any){
    return (
      <Link to={`/support/devices/logs?productId=${cellProps.original.productId}`}><i className="glyphicon glyphicon-list-alt"/></Link>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(UserProfileScreen));