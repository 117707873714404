import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import * as AppActions from "../../reducers/appReducer";

import UserScreenMenu from "./UserScreenMenu";
import UserBillingScreen from "./UserBillingScreen";
import UserProfileScreen from "./UserProfileScreen";

interface IUserScreenState {
  loading: boolean;
}

interface IUserScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
  match: any;
}

class UserScreen extends React.Component<IUserScreenProps, IUserScreenState> {

  constructor(props: IUserScreenProps){
    super(props);
    this.state = {
      loading: false,
    };

    this.fetchUserInfo = this.fetchUserInfo.bind(this);
  }
  public render(){
    const selectedScreen = this.props.match.params.selectedScreen && this.props.match.params.selectedScreen !== "" ?
      this.props.match.params.selectedScreen : "profile";
    const user = this.fetchUserInfo();
    return (
      <div>
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <UserScreenMenu 
            selectedScreen={selectedScreen}
            userId={this.props.match.params.userId}
            firstName={user.firstName}
            lastName={user.lastName}
            service={user.service} />
        </div>
      </div>
      
      <div className="row">
        { selectedScreen === "profile" ? <UserProfileScreen userId={this.props.match.params.userId} />: null }
        { selectedScreen === "billing" ? <UserBillingScreen userId={this.props.match.params.userId} />: null }
      </div>
      </div>
    );
  }

  private fetchUserInfo(){
    const userInfo = {
      firstName: "",
      lastName: "",
      service: ""
    };
    for(const user of this.props.appUsersDevicesState[this.props.appState.app].users){
      const currentId = parseInt(this.props.match.params.userId, 10);
      if(user.id === currentId){
        userInfo.firstName = user.firstName;
        userInfo.lastName = user.lastName;
        userInfo.service = user.service;
      }
    }
    return userInfo;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(UserScreen));