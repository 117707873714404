import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import Card from "../structure/Card";
import Table from "../structure/Table";

interface IDevicesListScreenState {
  loading: boolean;
}

interface IDevicesListScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
}

class DevicesListScreen extends React.Component<IDevicesListScreenProps, IDevicesListScreenState> {

  constructor(props: IDevicesListScreenProps){
    super(props);
    this.state = {
      loading: false
    };
  }

  public render(){
    let loading = this.state.loading;
    if(!this.props.appUsersDevicesState[this.props.appState.app]){
      loading = true;
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <Card title="Devices" loading={loading}>
          <Table
          data={this.props.appUsersDevicesState[this.props.appState.app].devices || []}
          columns={[
            {
              Header: "Type",
              accessor: "deviceType"
            },
            {
              Header: "Nickname",
              accessor: "nickname"
            },
            {
              Header: "Serial",
              accessor: "productId"
            },
            {
              Header: "Status",
              accessor: "status"
            },
            {
              Header: "Owner",
              columns: [{
                Header: "First",
                accessor: "registeredByInfo.firstName",
                Cell: this.renderUserCell
              },{
                Header: "Last",
                accessor: "registeredByInfo.lastName",
                Cell: this.renderUserCell
              },{
                Header: "Email",
                accessor: "registeredByInfo.email",
                Cell: this.renderUserCell
              }]
            },
            {
              Header: "",
              Cell: this.renderActionsCell,
              width: 20
            }
          ]} />
          </Card>
        </div>
      </div>
    );
  }

  private renderUserCell(cellProps: any){
    return (
      <Link to={`/support/users/${cellProps.original.id}`}>{cellProps.value}</Link>
    );
  }

  private renderActionsCell(cellProps: any){
    return (
      <Link to={`/support/devices/logs?productId=${cellProps.original.productId}`}><i className="glyphicon glyphicon-list-alt"/></Link>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(DevicesListScreen));