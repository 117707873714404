import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as moment from "moment";

import * as AppActions from "../../reducers/appReducer";
import Card from "../structure/Card";
import { error } from "../structure/Alert";
import Table from "../structure/Table";
import currency from "../../utils/currency";
import { setStripe } from "../../utils/changeHelpers";

import { BillingAPI } from "../../api";

interface IUserBillingScreenState {
  loading: boolean;
  billingHistory: any;
  billingDevices: any;
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cardVerificationCode: string;
}

interface IUserBillingScreenProps {
  appUsersDevicesState: any;
  userState: any;
  appState: any;
  userId: number;
}

class UserBillingScreen extends React.Component<IUserBillingScreenProps, IUserBillingScreenState> {

  constructor(props: IUserBillingScreenProps) {
    super(props);
    this.state = {
      loading: true,
      billingDevices: [],
      billingHistory: [],
      nameOnCard: "",
      cardNumber: "",
      expirationMonth: moment().format("MM"),
      expirationYear: moment().format("YY"),
      cardVerificationCode: "",
    };

    this.fetchBillingInformation = this.fetchBillingInformation.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
  }

  public componentDidMount() {
    this.fetchBillingInformation();
  }

  public async fetchBillingInformation() {
    this.setState({loading: true}, async () => {
      try {
        await setStripe(this.props.appState.app);
        const devicesResult = await BillingAPI.getBillingDevicesForUser(this.props.appState.app, this.props.userId);
        this.setState({
          loading: false,
          billingDevices: devicesResult.body.data,
        });
      } catch (err) {
        error("Could not download information for that user");
        this.setState({
          loading: false
        });
      }
    });
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <Card title="New Credit Card" loading={this.state.loading}>
              <div className="form-group">
                <label htmlFor="nameOnCard">Name on Card</label>
                <input
                  id="nameOnCard"
                  type="text"
                  className="form-control"
                  placeholder="Name on Card"
                  onChange={this.updateField} value={this.state.nameOnCard} />
              </div>
              <div className="form-group">
                <label htmlFor="cardNumber">Card Number</label>
                <input
                  id="cardNumber"
                  type="int"
                  className="form-control"
                  placeholder="Card Number"
                  onChange={this.updateField} value={this.state.cardNumber} />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="expirationDate">Expiration Month</label>
                    <select className="form-control" value={this.state.expirationMonth}
                      onChange={this.updateField} id="expirationMonth">
                      {this.getMonths()}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="expirationDate">Expiration Year</label>
                    <select className="form-control" value={this.state.expirationYear}
                      onChange={this.updateField} id="expirationYear">
                      {this.getYears()}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="cardVerificationCode">CVC</label>
                    <input
                      id="cardVerificationCode"
                      type="int"
                      className="form-control"
                      placeholder="CVC"
                      onChange={this.updateField} value={this.state.cardVerificationCode} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-primary" onClick={this.updatePaymentMethod}>Update Payment</button>
              </div>
            </Card>
          </div>
          <div className="col-md-8">
            <Card title="Billing Devices" loading={this.state.loading}>
              <Table
                data={this.state.billingDevices || []}
                columns={[
                  {
                    Header: "Created",
                    accessor: "created",
                    Cell: this.renderCreated
                  },
                  {
                    Header: "Type",
                    accessor: "deviceTypeDisplay"
                  },
                  {
                    Header: "Last Charged",
                    accessor: "lastCharged",
                    Cell: this.renderLastCharged
                  },
                  {
                    Header: "Paid Through",
                    accessor: "paidThrough",
                    Cell: this.renderPaidThrough
                  },
                  {
                    Header: "Plan Term",
                    accessor: "term"
                  },
                  {
                    Header: "Plan Amount",
                    accessor: "amount",
                    Cell: this.renderAmountCell
                  },
                ]} />
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private renderAmountCell(props: any) {
    return (
      <div>
        {currency(props.original.amount)}
      </div>
    );
  }

  private renderCreated(props: any) {
    return (
      <div>
        {moment(props.original.created).format("MM/DD/YYYY")}
      </div>
    );
  }

  private renderLastCharged(props: any) {
    return (
      <div>
        {moment(props.original.lastCharged).format("MM/DD/YYYY")}
      </div>
    );
  }

  private renderPaidThrough(props: any) {
    return (
      <div>
        {moment(props.original.paidThrought).format("MM/DD/YYYY")}
      </div>
    );
  }

  private updatePaymentMethod() {
    if (this.state.nameOnCard === "" || this.state.cardNumber === "" ||  this.state.cardVerificationCode === "") {
      return error("You must provide values for all fields!");
    }
    this.setState({loading: true}, () => {

      (window as any).Stripe.card.createToken({
        number: this.state.cardNumber,
        cvc: this.state.cardVerificationCode,
        exp_month: this.state.expirationMonth,
        exp_year: this.state.expirationYear
      }, async (status: number, response: any) => {
        if (status !== 200) {
          return this.setState({loading: false}, () => {
            return error(response);
          });
        } else {
          const extra = {
            token: response.id,
            name: this.state.nameOnCard,
            expMonth: response.card.exp_month,
            expYear: response.card.exp_year,
            type: response.card.brand,
            lastFour: response.card.last4
          };
          try{
            await BillingAPI.changePaymentMethod(this.props.appState.app, this.props.userId, response.id, extra);
          }catch(err){
            this.setState({loading: false});
            error("We could not update that payment method. Please try again in a few moments or contact engineering for support.");
          }
        } 
      });
    });
  }

  private updateField(e: any) {
    const s = this.state;
    s[e.target.id] = e.target.value;
    this.setState(s);
  }

  private getMonths() {
    const months = [];
    for (let month = 1; month <= 12; month++) {
      if (month < 10) {
        months.push(<option value={month} key={month}> 0{month} </option>);
      } else {
        months.push(<option value={month} key={month}> {month} </option>);
      }
    }
    return months;
  }

  private getYears() {
    const years = [];
    for (let year = 18; year <= 30; year++) {
      years.push(<option value={year} key={year}> 20{year} </option>);
    }
    return years;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    appUsersDevicesState: s.appUsersDevicesState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(UserBillingScreen));